import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    window.setTimeout(() => this.dismiss(), 7000)
  }

  dismiss() {
    this.element.remove()
  }
}
