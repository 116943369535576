import Controller from './base_controller'
import { formatDataAttributes } from '../module/data_attribute'

import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import Informer from '@uppy/informer'
import AwsS3 from '@uppy/aws-s3'
import ja from '@uppy/locales/lib/ja_JP'

import '@uppy/core/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/informer/dist/style.css'

export default class extends Controller {
  static get targets() {
    return ['fileInput', 'fileContainer', 'fileTemplate', 'informer']
  }

  connect() {
    ja.strings.exceedsSize =
      'ファイルサイズが大きすぎます。添付できるファイル：'
    this.initUppy()
  }

  initUppy() {
    this.filesMap = new Map()

    this.uppy = Uppy({
      autoProceed: true,
      restrictions: this.restrictions(),
      locale: ja,
      onBeforeFileAdded: (currentFile) => {
        const allowedTypes =
          this.fileInputTarget.dataset.allowedFileTypes.split(' ')

        if (!allowedTypes.includes(currentFile.type)) {
          this.uppy.info(
            '添付ファイルの中には不正なファイル形式が含まれています',
            'error',
            3000
          )
          return false
        }
      },
    })

    this.uppy.use(FileInput, {
      target: this.fileInputTarget,
      replaceTargetContent: true,
      pretty: true,
    })

    this.uppy.use(Informer, {
      target: this.informerTarget,
    })

    this.uppy.use(AwsS3, {
      limit: 2,
      timeout: 60 * 1000,
      serverUrl: '/',
      getUploadParameters: (file) => {
        const presignUrl = new URL(this.fileInputTarget.dataset.presignEndpoint)
        presignUrl.searchParams.append('filename', file.name)
        presignUrl.searchParams.append('contentType', file.type)

        return fetch(presignUrl.toString(), {
          method: 'get',
          credentials: 'include',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
      },
    })

    this.uppy.on('file-added', (file) => {
      const filePreview = this.createPreview(file)
      filePreview.classList.add('file-uploading')
      this.filesMap.set(file.id, filePreview)
      this.getController('inquiry-comment').disabledSendButton()
    })

    this.uppy.on('upload-error', (file) => {
      const filePreview = this.filesMap.get(file.id)
      filePreview
        .querySelector('[data-position="warning-icon"]')
        .classList.remove('hidden')
      this.setFileInfo(filePreview, file)
      this.uppy.info(
        '添付ファイルの中には不正なファイル形式が含まれています',
        'error',
        3000
      )
    })

    this.uppy.on('upload-success', (file) => {
      const filePreview = this.filesMap.get(file.id)
      this.setFileInfo(filePreview, file)

      this.filesMap.delete(file.id)
      this.getController('inquiry-comment').toggleSendButton()
    })
  }

  restrictions() {
    return {
      maxFileSize: 20 * 1000 * 1024,
      maxNumberOfFiles: formatDataAttributes(
        this.fileInputTarget.dataset.maxNumberOfFiles
      ),
    }
  }

  removeUppyFile(event) {
    const file = event.target.closest('[data-position="file"]')
    const uppyId = file.querySelector('.uppy-id').value

    this.uppy.removeFile(uppyId)
    this.filesMap.delete(uppyId)
    file.remove()
    this.getController('inquiry-comment').toggleSendButton()
  }

  createPreview(file) {
    if (!file) {
      return
    }

    this.fileTemplateTarget.querySelector('span.filename').innerText = file.name
    const newFile = this.fileTemplateTarget.lastChild.cloneNode(true)
    this.fileContainerTarget.appendChild(newFile)
    return newFile
  }

  setFileInfo(filePreview, file) {
    const timestamp = Date.now()
    const uppyIdInput = filePreview.querySelector('.uppy-id-template')
    const fileDataInput = filePreview.querySelector('.file-data-template')

    filePreview.querySelector('.loading-icon').remove()
    filePreview.querySelector('button').classList.remove('hidden')
    filePreview.classList.remove('file-uploading')

    uppyIdInput.classList.add('uppy-id')
    uppyIdInput.classList.remove('uppy-id-template')
    uppyIdInput.value = file.id

    fileDataInput.classList.add('file-data')
    fileDataInput.classList.remove('file-data-template')
    fileDataInput.setAttribute(
      'name',
      `inquiry_comment[comment_attachments_attributes][${timestamp}][file]`
    )
    fileDataInput.value = this.toShrineMeta(file)
  }

  toShrineMeta(uppyFile) {
    return JSON.stringify({
      id: uppyFile.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
      storage: this.fileInputTarget.dataset.storage,
      metadata: {
        size: uppyFile.size,
        filename: uppyFile.name,
        mime_type: uppyFile.type,
      },
    })
  }

  cancelAllFiles() {
    this.uppy.cancelAll()
  }
}
