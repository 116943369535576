import { Controller } from 'stimulus'

export default class extends Controller {
  getController(name) {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      name
    )
  }
}
