import { Controller } from 'stimulus'
import moment from 'moment'

export default class extends Controller {
  static get targets() {
    return ['startTimeFrom', 'startTimeTo']
  }

  restrictStartTimeBeforeYesterday() {
    const from = ''
    const to = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
    this.refreshDates(from, to)
  }

  restrictStartTimeFromToday() {
    const from = moment().startOf('day').format('YYYY-MM-DD')
    const to = ''
    this.refreshDates(from, to)
  }

  restrictStartTimeInThisMonth() {
    const from = moment().startOf('month').format('YYYY-MM-DD')
    const to = moment().endOf('month').format('YYYY-MM-DD')
    this.refreshDates(from, to)
  }

  restrictStartTimeInNextMonth() {
    const from = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD')
    const to = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')
    this.refreshDates(from, to)
  }

  refreshDates(from, to) {
    ;[
      [this.startTimeFromTarget, from],
      [this.startTimeToTarget, to],
    ].forEach(([input, newValue]) => {
      input.value = newValue
      const inputForMobile = input.nextElementSibling
      if (
        inputForMobile &&
        inputForMobile.classList &&
        inputForMobile.classList.contains('flatpickr-mobile')
      ) {
        inputForMobile.value = newValue
      }
    })
  }
}
