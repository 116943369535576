import Controller from './base_controller'
import Rails from '@rails/ujs'

export default class extends Controller {
  static get targets() {
    return [
      'body',
      'content',
      'bodyError',
      'template',
      'commentName',
      'commentTime',
      'commentBody',
      'sendButton',
      'fileContainer',
      'fileTemplate',
      'fileLink',
      'fileIcon',
    ]
  }

  create(event) {
    event.preventDefault()
    this.resetErrorMessages()
    this.disabledSendButton()

    fetch(this.element.dataset.inquiryCommentUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
      body: JSON.stringify({
        inquiry_comment: {
          body: this.bodyTarget.value,
          comment_attachments_attributes: this.commentAttachments(),
        },
      }),
    }).then((response) => {
      if (response.ok) {
        this.displayNewComment(response.json())
      } else if (response.status === 422) {
        this.displayBodyErrors(response.json())
      } else {
        throw new Error('You have encountered system error.')
      }
    })
  }

  commentAttachments() {
    const files = document.querySelectorAll('input.file-data')

    return Array.from(files).reduce((accumulator, file, index) => {
      accumulator[index] = { file: file.value }
      return accumulator
    }, {})
  }

  displayNewComment(responsePromise) {
    responsePromise.then((response) => {
      this.commentNameTarget.innerHTML = response.sender_name
      this.commentTimeTarget.innerHTML = response.formatted_created_at
      this.commentBodyTarget.innerHTML = response.formatted_body

      const newComment = this.templateTarget.lastChild.cloneNode(true)
      const fileContainer = newComment.querySelector(
        '[data-position="file-container"]'
      )
      const senderContainer = newComment.querySelector(
        '[data-position="sender-container"]'
      )

      this.displayFiles(fileContainer, response.files)
      this.displaySenderAvatar(senderContainer, response.sender_avatar_source)
      this.contentTarget.appendChild(newComment)
      this.bodyTarget.value = ''
      this.autoSize()
      this.toggleSendButton()
      this.clearUppyFilesCount()
      this.scrollToNewComment()
    })
  }

  autoSize() {
    this.bodyTarget.style.height = 'auto'
    this.bodyTarget.style.height = `${this.bodyTarget.scrollHeight}px`
  }

  displayBodyErrors(responsePromise) {
    responsePromise.then((response) => {
      if (response.errors) {
        this.bodyErrorTarget.innerHTML = response.errors
      }
    })
  }

  resetErrorMessages() {
    this.bodyErrorTarget.textContent = ''
  }

  toggleSendButton() {
    const commentExist = this.bodyTarget.value.length > 0
    const fileExist = this.element.querySelectorAll('.file-data').length > 0
    const noFileUploading =
      this.element.querySelectorAll('.file-uploading').length === 0

    if ((commentExist || fileExist) && noFileUploading) {
      this.enabledSendButton()
    } else {
      this.disabledSendButton()
    }
  }

  enabledSendButton() {
    this.sendButtonTarget.classList.add('btn-primary', 'font-bold')
    this.sendButtonTarget.classList.remove(
      'bg-white',
      'text-blue-700',
      'cursor-not-allowed',
      'border-blue-700'
    )
    this.sendButtonTarget.removeAttribute('disabled')
  }

  disabledSendButton() {
    this.sendButtonTarget.classList.remove('btn-primary', 'font-bold')
    this.sendButtonTarget.classList.add(
      'bg-white',
      'text-blue-700',
      'cursor-not-allowed',
      'border-blue-700'
    )
    this.sendButtonTarget.setAttribute('disabled', 'disabled')
  }

  cleanDataTarget(element) {
    Array.from(element.querySelectorAll('[data-target]')).forEach((el) =>
      el.removeAttribute('data-target')
    )
  }

  clearUppyFilesCount() {
    this.getController('uppy').cancelAllFiles()
  }

  scrollToNewComment() {
    const scrollElement = document.getElementById('comment-wrap')
    scrollElement.scrollTop =
      scrollElement.scrollHeight + this.bodyTarget.scrollHeight
  }
}
